.cursor-pointer {
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #3077b4 !important;
  border-color: #3077b4 !important;
  &:hover {
    opacity: 0.9;
  }
}

.btn-outline-primary {
  color: #3077b4;
  border-color: #3077b4 !important;
  &:hover {
    color: #fff;
    background-color: #3077b4 !important;
    border-color: #3077b4 !important;
  }
}

.btn-pill {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}

.btn-rounded {
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
