.form-control {
  &:focus {
    box-shadow: none !important;
  }
}

.invalid{
  &:focus {
    box-shadow: none !important;
    border-left: 5px solid red !important;
  }
}

.rolesSelect {
  &__value-container {
    border-left: 5px solid red;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
}

.file-upload {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  padding: 20px 66px 20px 20px;
  min-height: 140px;
  outline: #92b0b3 dashed 1px;
  outline-offset: -10px;
  position: relative;
  &.disabled{
    opacity: 0.5;
    pointer-events: none;
  }
}
