$iocn-primary: #3077b4; //

.text-iocn-primary {
  color:$iocn-primary;
}

.bg-iocn-primary {
  background: $iocn-primary;
}

.btn-iocn-primary {
  color: #fff;
  background-color: $iocn-primary !important;
  border-color: $iocn-primary !important;
}

//textarea:hover,
//input:hover,
//textarea:active,
//input:active,
//textarea:focus,
//input:focus,
//button:focus,
//button:active,
//button:hover,
//label:focus,
//.btn:active,
//.btn.active
//{
//  outline:0 !important;
//  -webkit-appearance:none;
//}
