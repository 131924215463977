html, body, #root {
  height: 100vh;
  //min-height: 100vh;
  background-color: #e4e5e6;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.loading-data {
  opacity: 0.7;
}

.text-ellipsis {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
