.form-control {
  &:focus {
    box-shadow: none !important;
  }
}

.invalid{
  &:focus {
    box-shadow: none !important;
    border-left: 5px solid red !important;
  }
}

.rolesSelect {
  &__value-container {
    border-left: 5px solid red;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
}


.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .15rem;

  .page-link {
    font-weight: 700;
  }

  .page-item:first-child .page-link {
    border-radius: 0;
  }

  .page-item:last-child .page-link {
    border-radius: 0;
  }

  .first-page {
    .page-link {
      border-right: 0;
    }
  }

  .last-page {
    .page-link {
      border-left: 0;
    }
  }



  .page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #3e8acc;
    background-color: #fff;
    border: 1px solid #dee2e6;
    &:hover {
      z-index: 2;
      color: #286396;
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }
  }

  .page-item {
    &.active {
      .page-link {
        z-index: 3;
        color: #fff;
        background-color: #3e8acc;
        border-color: #3e8acc;
        &:hover {
          z-index: 3;
          color: #fff;
          background-color: #3e8acc;
          border-color: #3e8acc;
        }
      }
    }
  }
}

