.form-control {
  &:focus {
    box-shadow: none !important;
  }
}

.invalid{
  &:focus {
    box-shadow: none !important;
    border-left: 5px solid red !important;
  }
}

.rolesSelect {
  &__value-container {
    border-left: 5px solid red;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
}

.file-upload {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  padding: 20px 66px 20px 20px;
  min-height: 140px;
  outline: #92b0b3 dashed 1px;
  outline-offset: -10px;
  position: relative;
}



#casefiles {
  .active {
    .nav-link {
      font-weight: bold;
      border-radius: 10px !important;
      border: 0 solid #3e8acc !important;
      color: #fff;
      background-color: #3e8acc;
    }
  }
  .nav-link {
    border-radius: 10px;
    font-weight: bold;

    cursor: pointer;
    color: #533f03;
    &:hover {
      text-decoration: none;
    }
  }
  .nav-separator {
    height: 1px;
    background-color: lightgray;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

