.input-group-prepend {
  display: contents;
}

h3 {
  font-weight: 300 !important;
}

.btn-pill {
  border-radius: 10rem;
  width: 32px;
  height: 32px;
}

.create-organization-btn-wrapper {
  top: 15px;
  right: 15px;
  .create-organization-btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding: 4px 8px;
  }
}

.searchFormFields {
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 5px 0 #ededed;
  border: 1px solid #ededed;
  position: relative;
  top: -18px;
}

.searchOpenBtn {
  position: absolute;
  right: 0;
  top: 10px;
}



.principalSearchField {
  background: #fff;
  border: 1px solid #e9ecef;
  padding: 25px 20px;
}

.valid {
  border-left: 5px solid green;
}

.invalid {
  border-left: 5px solid red;
}
.disabled-btn {
  color: #fff;
  background-color: #3e8acc;
  border-color: #3e8acc;
  opacity: 0.65;
  pointer-events: none;
}

.error {
  font-weight: 500;
  font-size: 12px;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.select-invalid {
  .rolesSelect {
    &__value-container {
        border-left: 5px solid red;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
    }
  }
}

.row-btn-wrapper {
  &:hover {
    background: #ffffff;
  }
  &:active {
    background: #ffffff!important;
  }
}

button[disabled] {
  cursor:not-allowed;
}

.no-events {
  pointer-events: none;
}

.select-valid {
  .rolesSelect {
    &__value-container {
      border-left: 0;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
  }
}

.events-none {
  pointer-events: none;
}


