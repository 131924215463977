// Navbar

.navbar-item-iocn {
  color: rgba(0,0,0,0.5);
  &:hover, &.is-active {
    color: rgba(0,0,0,0.8);
    a {
      //color: rgba(0,0,0,0.8) !important;
      color: #3077b4 !important;
    }
  }
}

.dropdown-item-iocn {
  display: flex;
  align-items: center;
  padding: 0  !important;
  .nav-link {
    width: 100%;
    color: rgba(0,0,0,0.8) !important;
    padding: 4px 24px !important;
  }

  &.active {
    background: #3e8acc;
    color: #ffffff;
    a {
      color: #ffffff !important;
    }
  }

  &:hover{
    background: #343a40;
    color: #ffffff;
    .nav-link {
      color: #ffffff !important;
    }
  }
}
