.login-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  border: 1px solid red;
}

.login-alert {
  position: absolute;
  right: 20px;
  z-index: 99;
}

.login-form {
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  max-width: 450px;
  min-width: 30vw;
  position: relative;
  padding: 0;
  box-shadow: 0 30px 60px 0 rgba(0,0,0,.3);
  text-align: center;

  .login-form-body {
    padding: 2rem;
  }

  .login-form-footer {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 1.5rem;
    text-align: center;
    border-radius: 0 0 10px 10px;

    .login-form-footer-message {
      cursor: pointer;
    }
  }

  .valid {
    border-left: 5px solid green;
  }

  .readonly {
    background-color: #e9ecef;
    opacity: 1;
    pointer-events: none;
  }

  .invalid {
    border-left: 5px solid red;
  }
  .disabled-btn {
    color: #fff;
    background-color: #3e8acc;
    border-color: #3e8acc;
    opacity: 0.65;
    pointer-events: none;
  }

  .password-error-message {
    small {
      font-weight: 500;
    }
  }
}
